export var CountryCode;
(function (CountryCode) {
    CountryCode["ASIA"] = "ASIA";
    CountryCode["AU"] = "AU";
    CountryCode["CA"] = "CA";
    CountryCode["DE"] = "DE";
    CountryCode["ES"] = "ES";
    CountryCode["FR"] = "FR";
    CountryCode["GB"] = "GB";
    CountryCode["IE"] = "IE";
    CountryCode["IN"] = "IN";
    CountryCode["IT"] = "IT";
    CountryCode["MA"] = "MA";
    CountryCode["NL"] = "NL";
    CountryCode["PL"] = "PL";
    CountryCode["PT"] = "PT";
    CountryCode["QC"] = "QC";
    CountryCode["SG"] = "SG";
    CountryCode["SN"] = "SN";
    CountryCode["TN"] = "TN";
    CountryCode["US"] = "US";
    CountryCode["WE"] = "WE";
    CountryCode["WS"] = "WS";
})(CountryCode || (CountryCode = {}));
export default CountryCode;
